const translate = {
	messages: {
		ru: {
			'Перейти в Личный кабинет': 'Перейти в Личный кабинет',
			'Обновить статус заявки': 'Обновить статус заявки',
			'Сегодня': 'Сегодня',
			'Сделка отменена': 'Сделка отменена',
			'Завтра': 'Завтра',
			'Вчера': 'Вчера',
			'Сделка': 'Сделка',
			'Встреча на СТО': 'Встреча на СТО',
			'Встреча с партнерами AUTO.RIA24': 'Встреча с партнерами AUTO.RIA24',
			'Заявка принята': 'Заявка принята',
			'Контактное лицо': 'Контактное лицо',
			'Дмитрий Ищенко': 'Дмитрий Ищенко',
			'Андрей Ткачук': 'Андрей Ткачук',
			'Заявка подтверждена': 'Заявка подтверждена',
			'Автовыкуп': 'Автовыкуп',
			'Спасибо': 'Спасибо',
			'Ваша заявка на продажу авто за 24 часа принята': 'Ваша заявка на продажу авто за 24 часа принята',
			'Продать за': 'Продать за',
			'Продать от': 'Продать от',
			'Статус моего предложения': 'Статус моего предложения',
			'Менеджер свяжется с вами в течение 10 минут и уточнит детали об авто': 'Менеджер свяжется с вами в течение 10 минут и уточнит детали об авто',
			'Что дальше': 'Что дальше',
			'Мы пригласим вас на экспертную проверку авто на одну из СТО': 'Мы пригласим вас на экспертную проверку авто на одну из СТО',
			'Наши специалисты проведут детальный, бесплатный осмотр вашего автомобиля': 'Наши специалисты проведут детальный, бесплатный осмотр вашего автомобиля',
			'Мы предложим лучшую рыночную цену по покупке вашего авто': 'Мы предложим лучшую рыночную цену по покупке вашего авто',
			'Оформляем продажу авто у нотариуса, и вы получаете деньги': 'Оформляем продажу авто у нотариуса, и вы получаете деньги',
			'Что взять с собой на встречу': 'Что взять с собой на встречу',
			'для оформления продажи': 'для оформления продажи',
			'Техпаспорт авто другие документы на авто при наличии': 'Техпаспорт авто (другие документы на авто при наличии)',
			'Паспорт и ИНН': 'Паспорт и ИНН',
			'Сервисная книжка при наличии': 'Сервисная книжка (при наличии)',
			'Второй комплект автомобильных ключей': 'Второй комплект автомобильных ключей',
			'Страховой полис': 'Страховой полис',
			'Спасибо за участие в программе AUTO.RIA24! Сейчас желающих приобрести ваше авто за 24 часа нет. Вам начислено 1 бесплатную публикацию для Быстрой продажи на AUTO.RIA': 'Спасибо за участие в программе AUTO.RIA24! \nСейчас желающих приобрести ваше авто за 24 часа нет. Вам начислено 1 бесплатную публикацию для Быстрой продажи на AUTO.RIA',
		},
		uk: {
			'Дмитрий Ищенко': 'Дмитро Іщенко',
			'Перейти в Личный кабинет': 'Перейти в Особистий кабінет',
			'Обновить статус заявки': 'Оновити статус заявки',
			'Сегодня': 'Сьогодні',
			'Сделка отменена': 'Угода скасована',
			'Завтра': 'Завтра',
			'Вчера': 'Вчора',
			'Сделка': 'Угода',
			'Встреча на СТО': 'Зустріч на СТО',
			'Встреча с партнерами AUTO.RIA24': 'Зустріч з партнерами AUTO.RIA24',
			'Контактное лицо': 'Контактна особа',
			'Заявка подтверждена': 'Заявка підтверджена',
			'Заявка принята': 'Заявка прийнята',
			'Андрей Ткачук': 'Андрій Ткачук',
			'Автовыкуп': 'Автовикуп',
			'Спасибо': 'Дякуємо',
			'Ваша заявка на продажу авто за 24 часа принята': 'Ваша заявка на продаж авто за 24 години прийнята',
			'Продать за': 'Продати за',
			'Продать от': 'Продати від',
			'Статус моего предложения': 'Статус моєї пропозиції',
			'Менеджер свяжется с вами в течение 10 минут и уточнит детали об авто': "Менеджер зв'яжеться з вами протягом 10 хвилин і уточнить деталі стосовно авто",
			'Что дальше': 'Що далі',
			'Мы пригласим вас на экспертную проверку авто на одну из СТО': 'Ми запросимо вас на експертну перевірку авто на одному з СТО',
			'Наши специалисты проведут детальный, бесплатный осмотр вашего автомобиля': 'Наші фахівці проведуть детальний, безкоштовний огляд вашого автомобіля',
			'Мы предложим лучшую рыночную цену по покупке вашего авто': 'Ми запропонуємо кращу ринкову ціну за покупку вашого авто',
			'Оформляем продажу авто у нотариуса, и вы получаете деньги': 'Проводимо оформлення продажу авто у нотаріуса, і ви отримуєте гроші',
			'Что взять с собой на встречу': 'Ми запропонуємо кращу ринкову ціну за покупку вашого авто',
			'для оформления продажи': 'для оформлення продажу',
			'Техпаспорт авто другие документы на авто при наличии': 'Техпаспорт авто (інші документи на авто за наявності)',
			'Паспорт и ИНН': 'Паспорт та ІПН',
			'Сервисная книжка при наличии': 'Сервісна книжка (за наявності)',
			'Второй комплект автомобильных ключей': 'Другий комплект автомобільних ключів',
			'Страховой полис': 'Страховий поліс',
			'Спасибо за участие в программе AUTO.RIA24! Сейчас желающих приобрести ваше авто за 24 часа нет. Вам начислено 1 бесплатную публикацию для Быстрой продажи на AUTO.RIA': 'Дякуємо за участь у програмі AUTO.RIA24! \nЗараз бажаючих придбати ваше авто за 24 години немає. Вам нараховано 1 безкоштовну публікацію для швидкого продажу на AUTO.RIA',
		}
	}
};
export default translate;
