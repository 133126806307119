<template>
    <div class="app-content m-padding">
        <h1 class="h1 bold size18">{{$t('Спасибо')}}{{userData.ownerName ? ', ' + userData.ownerName : ''}}! {{$t('Ваша заявка на продажу авто за 24 часа принята')}}!</h1>
        <div class="img-wrap">
            <div class="photo-responsive">
                <picture>
                    <img :src="photoUri"
                         loading="lazy"
                         onload="this.closest('.photo-responsive').classList.add('loaded');"
                         alt="pic"
                         :href="url"
                    />
                </picture>
            </div>
            <div class="block">
                <a :href="url" class="link size18">{{title}}</a>
                <h5 class="size16">
                  <template v-if="prices.showFrom">
                    {{$t('Продать от')}}
                    <span class="size22 bold price" v-if="prices.showFrom">{{humanizeNumber(prices.min)}} $</span>
                  </template>
                  <template v-else>
                    {{$t('Продать за')}}
                    <span class="size22 bold price" >{{humanizeNumber(prices.min)}} - {{humanizeNumber(prices.max)}} $</span>
                  </template>
                </h5>
                <a :href="langPrefix + '/cabinet/advertisements/draft/'" class="btn-blue text-c mhide">{{$t('Статус моего предложения')}}</a>
            </div>
        </div>
        <h3 class="bold size18">{{$t('Что дальше')}}:</h3>
        <Progress />
        <h3 class="size18"><span class="bold">{{$t('Что взять с собой на встречу')}}:</span> <br/> <span class="size13">({{$t('для оформления продажи')}})</span></h3>
        <ul class="unstyle check-list">
            <li class="item size13">{{$t('Техпаспорт авто другие документы на авто при наличии')}}</li>
            <li class="item size13">{{$t('Паспорт и ИНН')}}</li>
            <li class="item size13">{{$t('Сервисная книжка при наличии')}}</li>
            <li class="item size13">{{$t('Второй комплект автомобильных ключей')}}</li>
            <li class="item size13">{{$t('Страховой полис')}}</li>
        </ul>
        <a :href="langPrefix + '/cabinet/advertisements/draft/'" class="btn-blue text-c size16 dhide">{{$t('Статус моего предложения')}}</a>
    </div>

</template>
<script>
const storeModule = require("../../store/autoBuy");
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'AutoBuy',
  i18n: require('./i18n').default,
  mixins: [
    require('../../mixins/AutoRia24.logger'),
    require('../../mixins/humanize')
  ],
  components: {
    Progress: require('./Progress.vue').default
  },
  metaInfo() {
    return {
      title: `AUTO.RIA™ — ${this.$t('Автовыкуп')}`,
      meta: [
        { name: "description", content: "" },
        { name: "keywords", content: "" },
        { property: "og:title", content: "" },
        { property: "og:description", content: "" },
        { name: "ROBOTS", content: "NOINDEX, NOFOLLOW" }
      ],
      link: [
        { rel: "alternate", hreflang: "ru-UA", href: "https://auto.ria.com/" },
        { rel: "alternate", hreflang: "uk-UA", href: "https://auto.ria.com/uk/" },
        { rel: "alternate", hreflang: "ru", href: "https://auto.ria.com/" },
        { rel: "alternate", hreflang: "uk", href: "https://auto.ria.com/uk/" },
        { rel: "alternate", hreflang: "x-default", href: "https://auto.ria.com/" }
      ]
    };
  },
  init({store, route, context}) {
    store.registerModule('autoBuy', storeModule);
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }

    return Promise
        .all([
          store.dispatch('autoBuy/autoInfo', {autoId: route.to.params.id, cookies}),
          store.dispatch('autoBuy/userData', cookies)
        ])
        .catch(error => {
          console.error('ERROR:::dispatch autobuy info', error.message);
        })
        ;
  },
  mounted() {
    this
        .autoInfo({autoId: this.$route.params.id})
        .then((data) => {
          this.sendInitLog({
            ...data,
            action: 'init',
            page: '/autoBuy/:id',
            langId: this.langId,
            screenType: this.isMobile ? 'mobile' : 'desktop'
          });
        })
    ;
  },
  computed: {
    ...mapGetters({
      autoId: 'autoBuy/autoId',
      info: 'autoBuy/autoInfo',
      averagePrice: 'autoBuy/averagePrice',
      langPrefix: 'lang/prefix',
      userData: 'Common/userData'
    }),
    title() {
      const {model, brand, year} = this.info;
      if(model) {
        return `${brand.name} ${model.name} ${year}`;
      }
      return '';
    },
    url() {
      const {model, brand, id} = {} = this.info;
      return `${this.langPrefix}/auto_${brand.eng}_${model.eng}_${id}.html`;
    },
    photoUri() {
      const {photo} = this.info;
      return photo.url;
    },
    prices() {
      const {price: {min, max, price, showFrom} = {}} = {} = this.info;
      return {min, max, price, showFrom};
    },
    owner() {
      const {user = {}} = this.info || {};
      return user
    }
  },
  methods: {
    ...mapActions({
      autoInfo: 'autoBuy/autoInfo',
      _averagePrice: 'autoBuy/averagePrice',
      getUserData: 'Common/userData'
    }),
  }
}
</script>
<style lang="sass" src="./styles.sass"/>
