<template>
  <ul class="unstyle progress-list">
    <li class="item" :class="addClass">
      <div class="size13 date" v-if="dates.add">
        <span :class="{'date-color': addClass === 'active'}">{{ formatDate(dates.add) }}</span>
      </div>
      <div class="size16 bold">{{ $t('Заявка принята') }}</div>
      <div class="text size13" v-if="['active', ''].includes(addClass)">{{$t('Менеджер свяжется с вами в течение 10 минут и уточнит детали об авто')}}. </div>
    </li>
    <li class="item" :class="acceptClass">
      <div class="size13 date" v-if="dates.accept">
        <span :class="{'date-color': acceptClass === 'active'}"> {{ formatDate(dates.accept) }} </span>
      </div>
      <div class="size16 bold">{{ $t('Заявка подтверждена') }}</div>
      <div class="text size13" v-if="['active', ''].includes(acceptClass)">{{$t('Мы пригласим вас на экспертную проверку авто на одну из СТО')}}. </div>
    </li>
    <li class="item" :class="meetClass">
      <div class="size13 date" v-if="dates.meet">
        <span :class="{'date-color': meetClass === 'active'}">{{ formatDate(dates.meet) }}</span>
        <span class="hide">по адресу: ул. Акажемика Янгеля, д. 4</span>
      </div>
      <div class="size16 bold">{{ $t('Встреча с партнерами AUTO.RIA24') }}</div>
      <div class="text size13 mb-20" v-if="['active', ''].includes(meetClass)">
        {{ $t('Наши специалисты проведут детальный, бесплатный осмотр вашего автомобиля') }}.
      </div>
      <div class="text size13 mb-20" v-if="['active', ''].includes(meetClass)">
        {{ $t('Мы предложим лучшую рыночную цену по покупке вашего авто') }}.
      </div>
      <div class="size13 mb-10" v-if="['active', ''].includes(meetClass)">{{$t('Контактное лицо')}}: {{$t('Дмитрий Ищенко')}}, тел.: (073) 063 23 83</div>
    </li>
    <li class="item" :class="dealClass">
      <div class="size13 date" v-if="dates.deal">
        <span :class="{'date-color': dealClass === 'active'}">{{ formatDate(dates.deal) }}</span>
      </div>
      <template v-if="status === 4">
        <div class="size16 bold">{{$t('Сделка отменена')}}</div>
        <div class="text size13">{{ $t('Спасибо за участие в программе AUTO.RIA24! Сейчас желающих приобрести ваше авто за 24 часа нет. Вам начислено 1 бесплатную публикацию для Быстрой продажи на AUTO.RIA') }}.</div>
      </template>
      <template v-else>
        <div class="size16 bold">{{$t('Сделка')}}</div>
        <div class="text size13" v-if="['active', ''].includes(dealClass)">{{ $t('Оформляем продажу авто у нотариуса, и вы получаете деньги') }}.</div>
      </template>
    </li>
  </ul>
</template>
<script>
import {mapGetters} from 'vuex';
export default{
  name: 'Progress',
  i18n: require('./i18n').default,
  computed: {
    ...mapGetters({
      autoId: 'autoBuy/autoId',
      info: 'autoBuy/autoInfo',
      averagePrice: 'autoBuy/averagePrice',
      langPrefix: 'lang/prefix',
      userData: 'Common/userData'
    }),
    dates() {
      const {dates = {}} = {} = this.info
      return dates;
    },
    status() {
      return this.info ? this.info.status : -1;
    },
    addClass() {
      if(this.status === 4){
        return this.getFailedItemStatus(0);
      }
      return this.getItemClass(0)
    },
    acceptClass() {
      if(this.status === 4){
        return this.getFailedItemStatus(1);
      }
      return this.getItemClass(1);
    },
    meetClass() {
      if(this.status === 4){
        return this.getFailedItemStatus(2);
      }
      return this.getItemClass(2)
    },
    dealClass() {
      if(this.status === 4){
        return this.getFailedItemStatus(3);
      }
      return this.getItemClass(3)
    }
  },
  methods: {
    formatDate(_date) {
      if(!_date) return _date;
      const currentDate = this.m();
      const currentDay = Number(currentDate.format('DD'));

      //знаю хуйовий фікс, я поправлю
      const date = this.m(_date).add(-2, 'hours');
      const day = Number(date.format('DD'));

      let text = '';
      if(day === currentDay) {
        text = `${this.$t('Сегодня')},`;
      } else if(day === (currentDay - 1)) {
        text = `${this.$t('Вчера')},`;
      } else if(day === (currentDay + 1)) {
        text = `${this.$t('Завтра')},`;
      } else {
        text = date.format('LL')
      }

      text += ` ${date.format('HH:mm')}`
      return text;
    },
    getItemClass(status){
      if(status === this.status){
        return 'active';
      } else if(status < this.status) {
        return 'successfully';
      } else {
        return '';
      }
    },
    getFailedItemStatus(status) {
      if(status === 0 && this.dates.add && this.dates.accept) {
        return 'successfully';
      }
      if (status === 1 && this.dates.accept && this.dates.meet) {
        return 'successfully';
      }
      if (status === 2 && this.dates.meet && this.dates.deal){
        return 'successfully';
      }
      if(status === 3 && this.dates.deal && this.status !== 4){
        return 'successfully';
      }

      return 'fail';
    }
  }
}
</script>
