import Vue         from 'vue';
import _laxios     from '../_laxios';
const {autoBuy} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		autoId: 0,
		autoData: {},
		userData: {},
		page: {},
		averagePrice: null
	}, autoBuy),
	actions: {
		getStructure({ commit, getters, rootGetters }, { id, cookies }) {
			let {'lang/id': langId} = rootGetters;
			commit('autoId', {autoId: id});

			let headers = {};
			if (cookies) {
				headers.Cookie = cookies;
			}
			return _laxios
				.autoRia24SecondPageStrucuture
				.expandUrl({id})
				.request({params: {langId}, responseType: 'json', headers})
				.then(payload => commit('page', payload))
				.catch((error) => console.error('Error /24/api/public/view/second-step/ =>', error.message));
		},
		autoInfo({commit, getters, rootGetters}, {autoId, cookies}) {
			let sendRequest = false;
			let {'lang/id': langId} = rootGetters;
			commit('autoId', {autoId});

			let headers = {};
			if (cookies) {
				headers.Cookie = cookies;
			}

			if (!Object.keys(getters.autoInfo).length) {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios
						.getAutoBuyInfo
						.expandUrl({id: autoId})
						.request({params: {langId, autoId}, responseType: 'json', headers})
						.then((data) => {
							commit('autoInfo', data);
							return data;
						})
						.catch((error) => {
							console.error('api error');
							return {};
						})
						;
			}
			return Promise.resolve(getters.autoInfo);
		},
		averagePrice({commit, getters}) {
			const autoInfo = getters.autoInfo;
			if (!autoInfo || !autoInfo.advertisement){
				return Promise.resolve({});
			}

			if (!getters.averagePrice) {
				const advertisement = autoInfo.advertisement;
				return _laxios
						.averagePrice
						.request({
							params: {
								categoryId: advertisement.autoData.categoryId,
								brandId: advertisement.markId,
								modelId: advertisement.markId,
								year: advertisement.autoData.year,
								custom: advertisement.autoData.custom,
								onRepairParts: 0,
								damage: 0,
								underCredit: 0,
								currencyId: 0,
								gearId: advertisement.autoData.gearBoxId || 0,
								fuelId: advertisement.autoData.fuelId || 0,
								engineVolume: advertisement.autoData.fuelId || 0,
							}
						});
			}

			return Promise.resolve(getters.averagePrice);
		},
		userData({commit, rootGetters, getters}, Cookie) {
			let sendRequest = false;
			const headers   = {};
			if (Cookie) {
				headers.Cookie = Cookie;
			}

			if (!Object.keys(getters.userData).length) {
				sendRequest = true;
			}

			if (sendRequest) {
				return _laxios
						.userData
						.request({responseType: 'json', headers})
						.then((userData) => {
							commit('userData', userData);
							return userData;
						})
						.catch((err) => console.error('Ошибка при получении userData', err.message));
			}
			else {
				return getters.userData;
			}
		}
	},
	mutations: {
		autoInfo(state, autoData) {
			for (let key in autoData) {
				if (autoData.hasOwnProperty(key)) {
					Vue.set(state.autoData, key, autoData[key]);
				}
			}
		},
		autoId(state, {autoId}) {
			Vue.set(state, 'autoId', autoId);
		},
		averagePrice(state, payload){
			Vue.set(state, 'averagePrice', payload);
		},
		userData(state, userData) {
			Vue.set(state, 'userData', userData);
		},
		page(state, payload) {
			Vue.set(state, 'page', payload);
		}
	},
	getters: {
		structure({ page: {structure = []} = {} }) {
			return structure;
		},
		autoInfo({autoData}) {
			return autoData;
		},
		autoId({autoId}) {
			return autoId;
		},
		userData({userData}){
			return userData;
		},
		averagePrice({averagePrice}){
			return averagePrice;
		}
	}
};
